<template>
  <div v-if="error">
    <section id="intro" class="section text-center">
      <div class="section-inner">
        <b-container>
          <b-row>
            <b-col class="intro-text">
              <h2>Je tu čisto, ale stránku jsme nenašli</h2>
              <div>
                <b-button
                  to="/"
                  variant="primary"
                  class="mt-3 mt-xl-4 mx-2"
                >
                  Návrat domů
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'PageError',
  props: {
    error: {
      type: Object,
      required: true
    }
  },
  head: () => ({
    title: 'Chyba',
    bodyAttrs: {
      id: 'error-page'
    }
  })
}
</script>
