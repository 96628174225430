<template>
  <div class="default-layout">
    <jb-header />

    <LazyHydrate when-visible>
      <main-intro-section />
    </LazyHydrate>

    <Nuxt />

    <LazyHydrate when-visible>
      <jb-footer />
    </LazyHydrate>

    <client-only>
      <jb-scroll-menu />
    </client-only>
    <div v-if="$nuxt.isOffline" class="offline-bar">
      <div>Právě jste offline.</div>
      <div>Některé části stránky nemusí fungovat.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayoutDefault',
  components: {
    JbScrollMenu: () => import('~/components/Core/JbScrollMenu/JbScrollMenu.vue'),
    JbHeader: () => import('~/components/Core/JbHeader/JbHeader.vue'),
    MainIntroSection: () => import('~/components/Sections/MainIntroSection/MainIntroSection'),
    JbFooter: () => import('~/components/Core/JbFooter/JbFooter.vue')
  },
  head: () => ({
    // title: 'Úvod',
  })
}
</script>
